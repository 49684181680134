// import { Router } from "express";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store";

// import pages
import LoginUser from "./components/pages/LoginUser.vue";
import HomePage from "./components/pages/HomePage.vue";
import DashBoard from "./components/pages/DashBoard";
import Clients from "./components/pages/clients";
import Payments from "./components/pages/payments";
import MpesaPayments from "./components/pages/mpesaPayments";
import requestParcel from "./components/pages/requestParcel.vue";
import alerts from "./components/pages/alerts";

import Reports from "./components/pages/reports";
// import SendMessages from "./components/pages/sendMessages";
import simpleSms from "./components/pages/simpleSms";
import apps from "./components/pages/apps"
import RegisterUser from "./components/pages/RegisterUser"
import messages from "./components/pages/messages"
import parcels from "./components/pages/parcels"
import smsReport from "./components/pages/smsReport"
// import simpleSms from "./components/pages/simpleSms"
// import LoginUser from "./components/pages/LoginUser"
// import store from './store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    { path: "/request/parcel", component: requestParcel },
    {path: "/alerts", component: alerts},
    { path: "/login", component: LoginUser, meta: { auth: false } },
    { path: "/register", component: RegisterUser, meta: { auth: false } },
    // { path: "/register", component: RegisterUser, meta: { auth: false } },
    { path: "/dashboard", component: DashBoard, meta: { auth: true }  },
    { path: "/apps", component: apps, meta: { auth: true }  },

    
    { path: "/reports", component: Reports, meta: { auth: true }  },
    {
      path: "/register/clients",
     
    },
    { path: "/clients", component: Clients, meta: { auth: true } },
    { path: "/messages", component: messages, meta: { auth: true }  },
    { path: "/send/simple/sms", component: simpleSms, meta: { auth: true }  },
    { path: "/sms/report", component: smsReport, meta: { auth: true }  },
  
    { path: "/payments", component: Payments, meta: { auth: true } },
    {path: "/parcels", component: parcels, meta:{auth:true}},
    { path: "/mpesa/payments", component: MpesaPayments, meta: { auth: true } },


  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ path: '/Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;
