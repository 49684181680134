<template>
   <div>
    <main-navigation></main-navigation>
    <div class="container">
    <div class="container-fluid">

  
   <div class="fw-b fs-3">
    Dashboard

   </div>
    <div class="row">
      <div class="col-md-2">
        <div class="card text-dark bg-light h-100">
          <div class="card-header">Parcels</div>
          <div class="card-body">
            <h5 class="card-title">Total Parcels</h5>
            <small>
              <p class="card-text">1030 parcels collected.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card text-dark bg-light h-100">
          <div class="card-header">Estates</div>
          <div class="card-body">
            <h5 class="card-title">Total Estates</h5>
            <small>
              <p class="card-text">20 estates served.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card text-dark bg-light h-100">
          <div class="card-header">Riders</div>
          <div class="card-body">
            <h5 class="card-title">Total Riders</h5>
            <small>
              <p class="card-text">10 riders registered.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card text-dark bg-light h-100">
          <div class="card-header">Collection</div>
          <div class="card-body">
            <h5 class="card-title">Amount collected</h5>
            <small>
              <p class="card-text">20000 Kshs collected .</p>
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card text-dark bg-light h-100">
          <div class="card-header">Arrears</div>
          <div class="card-body">
            <h5 class="card-title">Uncollected</h5>
            <small>
              <p class="card-text">2000 Kshs arreas to be collected</p>
            </small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="card text-dark bg-light h-100">
          <div class="card-header">Revenue</div>
          <div class="card-body">
            <h5 class="card-title">Montly collections</h5>
            <small>
              <p class="card-text">20,000 Kshs total revenues to be collected monthly.</p>
            </small>
          </div>
        </div>
      </div>
    </div>
</div>

</div>
  </div>
</template>