<template>
  <div>
    <main-navigation></main-navigation>

    <div class="container">
      

      <base-card>
        <form class="row g-3" @submit.prevent="requestParcel">
          <div class="col-md-6">
            <label for="names" class="form-label">Names</label>
            <input
              type="text"
              class="form-control"
              id="names"
              v-model="formData.client_name"
            />
          </div>
          <div class="col-md-6">
            <label for="phone" class="form-label">Phone Number</label>
            <input
              type="phone"
              class="form-control"
              id="phone"
              v-model="formData.client_phone"
            />
          </div>
          <div class="col-md-6">
            <label for="from" class="form-label">From</label>
            <select
              id="inputState"
              class="form-select"
              v-model="formData.sent_from"
            >
              <option selected>Select pickup point</option>
              <option>Nairobi CBD</option>
              <option>Westlands</option>
              <option>Kilimani</option>
              <option>Pangani</option>
              <option>Ngara</option>
              <option>South B/C</option>
              <option>Ngon road</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="from" class="form-label">To</label>
            <select
              id="inputState"
              class="form-select"
              v-model="formData.sent_to"
            >
              <option selected>Select drop point</option>
              <option>Katani</option>
              <option>Syokimau</option>
              <option>Katani road</option>
              <option>Airport Road</option>
              <option>Mwananchi road</option>
            </select>
          </div>
          <div class="col-12">
            <label for="description" class="form-label">Description</label>
            <textarea
              type="text"
              class="form-control"
              name="description"
              id="description"
              cols="100"
              rows="3"
              placeholder=""
              v-model="formData.description"
            ></textarea>
          </div>
          <div>
            <small class="text-muted fs-6 text">
          Provide details  to enable us collect the parcel. In the
          decription, give all details including the phone number and name of
          person to give the parcel. A staff with a badge from Roberms will
          collect and deliver
        </small>
          </div>

          <div class="col-12">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </base-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      formData: {
        client_name: "",
        client_phone: "",
        sent_from: "",
        sent_to: "",
        description: "",
      },
    };
  },
  methods: {
    async requestParcel() {
      const response = await axios.post(
        "https://api.roberms.com/delivery/request/parcel",
        this.formData
      );
      console.log(response);

      this.$router.replace("/");
      console.log(this.formData);
    },
  },
};
</script>
