<template>
  <div>
    <main-navigation></main-navigation>
    <br>
    <div class="container">
    
   
<div class="row">
  <div class="col-lg-10">

  </div>
  <div class="col-lg-2 container" >
    <div class="form-outline">
  <input type="search" id="form1" class="form-control" v-model="search" placeholder="Search parcel"  aria-label="Search"  />
</div>
  </div>

</div>

    <div class="container">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Client name</th>
            <th scope="col">client phone</th>
            <th scope="col">Sent from</th>
            <th scope="col">Delivered to</th>
            <th scope="col">Date</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="parcel in filteredparcels" :key="parcel.id">
            <th scope="row">{{ parcel.client_name }}</th>
            <td>{{ parcel.client_phone }}</td>
            <td>{{ parcel.sent_from }}</td>
            <td>{{ parcel.sent_to }}</td>
            <!-- <td>{{ parcel.reg_date }}</td> -->
            <td><small>{{ new Date(parcel.reg_date).toLocaleString("nl-NL") }}</small></td>
           
              <td> <small>{{ parcel.description }}</small></td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },

  computed: {
    parcels() {
      return this.$store.getters.parcels;
    },
    filteredparcels() {
      return this.parcels
        .filter(
          (parcel) =>
            parcel.client_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) |
            parcel.client_phone.toString().includes(this.search.toLowerCase()) |
            parcel.sent_from.toString().includes(this.search.toLowerCase()) |
            parcel.sent_to.toString().includes(this.search.toLowerCase()) |
            parcel.description.toString().includes(this.search.toLowerCase()) |
            parcel.reg_date.toString().includes(this.search.toLowerCase())
        )
        .slice(0, 7);
    },
  },
  created() {
    this.$store.dispatch("LoadParcels");
  },
};
</script>
